import React from "react";

function Footer() {
  return (
    <div className="Footer">
      <h1>Checkout @zxygallery on instagram for more info </h1>
    </div>
  );
}

export default Footer;
