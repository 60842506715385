import React from "react";

function Title() {
  return (
    <div className="Title">
      <h1>Roman Kalinovski - "Re-imagined Narratives"</h1>
    </div>
  );
}

export default Title;
